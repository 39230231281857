/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import classNames from "classnames";
import { Formik } from "formik";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import {
  generateSMSVerification,
  validateEmail,
  validatePhoneNumber,
} from "../../../api/users.ts";
import {
  SIGN_UP_COLLECT_BASIC_INFO_STEP,
  SIGN_UP_PROCESS_STEP,
} from "../../../commons/signup.constants";
import SignupContext from "../../../context/sign-up-context";
import { useCompanyInfo } from "../../../hooks";
import { checkEmailVerification } from "../../../utils/email-verification";
import {
  extractUserReferralFromUrl,
  getCurrentPath,
} from "../../../utils/get-params-value";
import { validatePassword } from "../../../utils/password-validate";
import CustomSignupButton from "./custom-signup-button";
import CustomSignupInput from "./custom-signup-input";

const INVALID_PHONE_NUMBER_MESSAGE = "This phone number is invalid";
const PASSWORD_MIN_LENGTH_MESSAGE = "Password must be at least 6 characters";
const INVALID_EMAIL_MESSAGE = "Invalid email address";
const DEFAULT_PHONE_NUMBER = "";

const NewSignupForm = ({ autoFocus = true }) => {
  const { data: companyInfo } = useCompanyInfo();
  const pathName = getCurrentPath();
  const formRef = useRef();
  const phoneRef = useRef();
  const [loadingPage, setLoadingPage] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [, referralCode] = extractUserReferralFromUrl(pathName);

  const {
    collectBasicInfoStep,
    params,
    setParams,
    setFieldError,
    fieldError,
    setCollectBasicInfoStep,
    setSignupProcess,
    setTimeExpire,
  } = useContext(SignupContext);
  const {
    firstName = "",
    phoneNumber = "",
    email = "",
    password = "",
  } = params;
  const {
    firstName: nameError = "",
    phoneNumber: phoneError = "",
    email: emailError = "",
    password: passwordError = "",
  } = fieldError;
  const phoneNumberWithCountryCode = useMemo(() => {
    let phoneNumberBeginWithoutZero = phoneNumber;
    if (phoneNumber.charAt(0) === "0") {
      phoneNumberBeginWithoutZero = phoneNumberBeginWithoutZero.substring(1);
    }
    return `+${countryCode}${phoneNumberBeginWithoutZero}`;
  }, [countryCode, phoneNumber]);
  useEffect(() => {
    if (
      !!referralCode &&
      referralCode !== "signup" &&
      referralCode !== "referral"
    ) {
      setParams((prevState) => ({
        ...prevState,
        referralBy: referralCode,
      }));
    }
  }, [referralCode]);

  useEffect(() => {
    import("libphonenumber-js/min");
  }, []);

  const handleClickButton = () => {
    switch (collectBasicInfoStep) {
      case SIGN_UP_COLLECT_BASIC_INFO_STEP.FULL_NAME:
        if (firstName && !nameError) {
          setCollectBasicInfoStep(SIGN_UP_COLLECT_BASIC_INFO_STEP.PHONE_NUMBER);
        }
        break;
      case SIGN_UP_COLLECT_BASIC_INFO_STEP.PHONE_NUMBER:
        if (phoneNumber && !phoneError) {
          (async () => {
            try {
              const { parsePhoneNumber } = await import(
                "libphonenumber-js/min"
              );
              const isValid = parsePhoneNumber(
                phoneNumberWithCountryCode
              ).isValid();
              if (!isValid) {
                throw new Error({
                  data: {
                    message: INVALID_PHONE_NUMBER_MESSAGE,
                  },
                });
              }
              await validatePhoneNumber(phoneNumber);

              setCollectBasicInfoStep(SIGN_UP_COLLECT_BASIC_INFO_STEP.EMAIL);
            } catch (error) {
              phoneRef.current.numberInputRef.focus();
              const { data: { message = "" } = {} } = error;
              setFieldError((prevFieldError) => ({
                ...prevFieldError,
                phoneNumber: message || INVALID_PHONE_NUMBER_MESSAGE,
              }));
            }
          })();
        }
        if (phoneError) {
          phoneRef.current.numberInputRef.focus();
        }

        break;
      case SIGN_UP_COLLECT_BASIC_INFO_STEP.EMAIL:
        if (email && !emailError) {
          (async () => {
            try {
              await validateEmail(email);
              setCollectBasicInfoStep(SIGN_UP_COLLECT_BASIC_INFO_STEP.PASSWORD);
            } catch (error) {
              const { data: { message = "" } = {} } = error;
              setFieldError((prevFieldError) => ({
                ...prevFieldError,
                email: message,
              }));
            }
          })();
        }
        break;
      case SIGN_UP_COLLECT_BASIC_INFO_STEP.PASSWORD:
        if (password && !passwordError) {
          (async () => {
            try {
              const codeId = await generateSMSVerification(phoneNumber, email);
              const { code_id = "", time_expire = 0 } = codeId;
              setCollectBasicInfoStep(SIGN_UP_COLLECT_BASIC_INFO_STEP.DONE);
              setTimeExpire(time_expire);
              setParams((prevState) => ({
                ...prevState,
                codeId: code_id,
              }));
              setLoadingPage(true);
              setTimeout(() => {
                setLoadingPage(false);
                setParams((prevState) => ({
                  ...prevState,
                  phoneNumber: phoneNumberWithCountryCode,
                }));
                setSignupProcess(SIGN_UP_PROCESS_STEP.VERIFY_PHONE_NUMBER);
              }, 1000);
            } catch (error) {
              setFieldError((prevFieldError) => ({
                ...prevFieldError,
                password: "Something went wrong, please try again",
              }));
            }
          })();
        }
        break;

      default:
        setCollectBasicInfoStep(SIGN_UP_COLLECT_BASIC_INFO_STEP.FULL_NAME);
        break;
    }
  };
  const handleChangeValue = (item) => {
    const { name, value } = item;
    const currentValue = formRef.current.values;
    formRef.current.setValues({ ...currentValue, [name]: value });
    setParams({ ...params, [name]: value });
  };
  const handleOnChangePhoneNumber = (item) => {
    const { name, value, dialCode } = item;
    if (countryCode !== dialCode) {
      setCountryCode(dialCode);
    }
    const currentValue = formRef.current.values;
    formRef.current.setValues({ ...currentValue, [name]: value });
    setParams({ ...params, [name]: value });
  };

  const handleClickInput = (value) => {
    if (collectBasicInfoStep !== value) {
      setCollectBasicInfoStep(value);
    }
  };
  useEffect(() => {
    if (collectBasicInfoStep === 1) {
      phoneRef.current.numberInputRef.focus();
    }
  }, [collectBasicInfoStep]);
  useEffect(() => {
    formRef.current.setErrors({ ...fieldError });
  }, [fieldError]);

  return (
    <div className="body-context-container">
      <Formik
        innerRef={formRef}
        initialValues={params}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = "Required";
            setFieldError((prevFieldError) => ({
              ...prevFieldError,
              email: "Required",
            }));
          } else if (checkEmailVerification(values.email)) {
            errors.email = INVALID_EMAIL_MESSAGE;
            setFieldError({
              email: INVALID_EMAIL_MESSAGE,
            });
          } else {
            setFieldError((prevFieldError) => ({
              ...prevFieldError,
              email: "",
            }));
          }
          if (!values.firstName) {
            errors.firstName = "Required";
          } else {
            setFieldError((prevFieldError) => ({
              ...prevFieldError,
              firstName: "",
            }));
          }
          if (!values.phoneNumber) {
            errors.phoneNumber = "Required";
          } else {
            setFieldError((prevFieldError) => ({
              ...prevFieldError,
              phoneNumber: "",
            }));
          }
          if (!values.password) {
            errors.password = "Required";
          } else {
            const checkValidatePassword = validatePassword(values.password);
            if (!checkValidatePassword.verified) {
              errors.password = PASSWORD_MIN_LENGTH_MESSAGE;
              setFieldError((prevFieldError) => ({
                ...prevFieldError,
                password: PASSWORD_MIN_LENGTH_MESSAGE,
              }));
            } else {
              setFieldError((prevFieldError) => ({
                ...prevFieldError,
                password: "",
              }));
            }
          }
          return errors;
        }}
      >
        {({ values, errors, touched, handleBlur, handleSubmit }) => (
          <form className="signup-form" onSubmit={handleSubmit}>
            <CustomSignupInput
              question="What's your full name?"
              activatedStep={0}
              isDone={collectBasicInfoStep > 0}
              isActivated={collectBasicInfoStep >= 0}
              placeholder="John Citizen"
              handleChange={(e) => {
                handleChangeValue(e.target);
              }}
              handleBlur={handleBlur}
              fieldName="firstName"
              value={values.firstName}
              error={errors.firstName}
              touched={touched.firstName}
              handleClickButton={handleClickButton}
              className="signup-0"
              handleClickInput={() => {
                handleClickInput(0);
              }}
              autoFocus={autoFocus}
            />
            <CustomSignupInput
              question="And your phone number?"
              activatedStep={1}
              isDone={collectBasicInfoStep > 1}
              isActivated={collectBasicInfoStep >= 1}
              className="signup-1"
              customInputElement={
                <>
                  <div className="d-flex w-100 position-relative">
                    <PhoneInput
                      ref={phoneRef}
                      value={params?.phoneNumber || DEFAULT_PHONE_NUMBER}
                      masks={{ au: ".... .... .... ...." }}
                      enableSearch={false}
                      onlyCountries={["au", "nz", "vn"]}
                      placeholder="Input your phone number"
                      disableCountryCode
                      country="au"
                      onClick={() => {
                        handleClickInput(1);
                        phoneRef.current.numberInputRef.focus();
                      }}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          handleClickButton();
                        }
                      }}
                      onChange={(value, country) => {
                        handleOnChangePhoneNumber({
                          name: "phoneNumber",
                          value,
                          dialCode: country.dialCode,
                        });
                      }}
                    />
                    <div
                      className={classNames(
                        "custom-input-button-wrapper",
                        "phone-input-button",
                        {
                          "d-none": phoneNumber.length < 4,
                        }
                      )}
                    >
                      <CustomSignupButton onClick={handleClickButton}>
                        {collectBasicInfoStep !== 1 ? <CheckIcon /> : "Next"}
                      </CustomSignupButton>
                    </div>
                  </div>
                  {fieldError.phoneNumber && (
                    <div className="text-danger">{fieldError.phoneNumber}</div>
                  )}
                </>
              }
            />
            <CustomSignupInput
              question="Next, your email."
              activatedStep={2}
              isDone={collectBasicInfoStep > 2}
              isActivated={collectBasicInfoStep >= 2}
              placeholder="clint@wealthx.au"
              handleChange={(e) => {
                handleChangeValue(e.target);
              }}
              handleBlur={handleBlur}
              fieldName="email"
              value={values.email}
              error={errors.email}
              touched={touched.email}
              handleClickButton={handleClickButton}
              className="signup-2"
              handleClickInput={() => {
                handleClickInput(2);
              }}
            />

            <CustomSignupInput
              fieldType="password"
              question="Finally, your password."
              activatedStep={3}
              isDone={collectBasicInfoStep > 3}
              isActivated={collectBasicInfoStep >= 3}
              placeholder="********"
              handleChange={(e) => {
                handleChangeValue(e.target);
              }}
              handleBlur={handleBlur}
              fieldName="password"
              value={values.password}
              error={errors.password}
              touched={touched.password}
              handleClickButton={handleClickButton}
              className="signup-3"
            />
          </form>
        )}
      </Formik>
      {collectBasicInfoStep === 3 && (
        <div className="login-privacy">
          By clicking next you agree with {companyInfo?.companyName}. <br />
          <span>Terms & Conditions</span> and <span>Privacy Policy</span>
        </div>
      )}
      {loadingPage && (
        <div className="signup-loading">
          <CircularProgress color="inherit" />
        </div>
      )}
    </div>
  );
};

export default NewSignupForm;
